import React from "react"
import moment from "moment"
import axios from "axios"

import calendar from "./../../images/icons/calendar.svg"

import "./AppointmentForm.css"

import { Form, Field } from "react-final-form"

export default function AppointmentForm() {
  return (
    <Form
      onSubmit={values => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/

        const errors = {}

        const requiredFields = ["name", "phone", "date", "time", "email"]

        requiredFields.forEach(field => {
          const fieldName = field.toLowerCase()

          if (!values[fieldName]) {
            errors[fieldName] = "Required"
          }

          if (
            fieldName === "email" &&
            values.email &&
            !emailRegex.test(String(values.email).toLowerCase())
          ) {
            errors.email = "Invalid Email"
          }

          if (
            fieldName === "phone" &&
            values.phone &&
            !phoneRegex.test(String(values.phone).toLowerCase())
          ) {
            errors.phone = "Invalid Number"
          }
        })

        if (values.date) {
          const currentDate = moment()
          const chosenDate = moment(values.date)

          if (chosenDate < currentDate) {
            errors.date = "Invalid Date"
          }
        }

        if (Object.keys(errors).length) return errors

        if (values.sweet) {
          //honey pot has been filled in, this is a bot, don't actually send the email
          return
        }

        axios.post(
          `${process.env.MAIL_SERVER}`,
          {
            from: "Leda Homecare <website@ledahomecare.co.uk>",
            to: "admin@ledahomecare.co.uk",
            subject: `Appointment Request - ${values.date} at ${values.time}`,
            text: `${values.name} has requested an appointment at ${values.time} on ${values.date}. Please reply to this email, call ${values.phone}, or email ${values.email} to arrange this appointment.`,
            replyTo: values.email,
            domain: "ledahomecare.co.uk",
            html: `<p>${values.name} has requested an appointment at ${values.time} on ${values.date}.</p><p>Please reply to this email, call ${values.phone}, or email ${values.email} to arrange this appointment.</p>`,
            autoResponse: {
              from: "Leda Homecare <website@ledahomecare.co.uk>",
              to: values.email,
              replyTo: "admin@ledahomecare.co.uk",
              subject: "Message Recieved",
              text:
                "Thank you for getting in touch with Leda Homecare, we will reply shortly. Kind Regards, Leda Homecare",
              html:
                "<p>Thank you for getting in touch with Leda Homecare, we will reply shortly.</p> <p> Kind Regards,</p> <p>Leda Homecare</p> ",
            },
          },
          {
            headers: {
              Authorization: `Basic ${Buffer.from(
                `Leda Homecare:f16978ff4a636fc337036a55e0c9e4b0`
              ).toString("base64")}`,
            },
          }
        )
      }}
      render={({ handleSubmit, submitSucceeded }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className="appointment-form row">
            <div className="four columns">
              <div className="appointment-title">
                <div>
                  <img className="icon" src={calendar} alt="" />
                  <h3>Book an Appointment</h3>
                </div>
              </div>
            </div>
            <div className="eight columns">
              <div className="form">
                <div className="row">
                  <div className="inputs">
                    <Field name="name">
                      {({ input, meta }) => (
                        <input
                          placeholder="Name"
                          type="text"
                          className={
                            !!meta.touched &&
                            !!meta.submitError &&
                            !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="date">
                      {({ input, meta }) => (
                        <input
                          placeholder="Date"
                          type="date"
                          className={
                            meta.touched &&
                            meta.submitError &&
                            !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="phone">
                      {({ input, meta }) => (
                        <input
                          placeholder="Phone"
                          type="number"
                          className={
                            meta.touched &&
                            meta.submitError &&
                            !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="time">
                      {({ input, meta }) => (
                        <input
                          placeholder="Time"
                          type="time"
                          className={
                            meta.touched &&
                            meta.submitError &&
                            !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="email">
                      {({ input, meta }) => (
                        <input
                          type="email"
                          {...input}
                          id="email"
                          placeholder="Email"
                          className={
                            meta.touched &&
                            meta.submitError &&
                            !meta.dirtySinceLastSubmit
                              ? "error"
                              : ""
                          }
                          style={{ width: "90%" }}
                        />
                      )}
                    </Field>
                    <Field name="sweet">
                      {({ input }) => (
                        <input
                          type="text"
                          {...input}
                          style={{ display: "none" }}
                        />
                      )}
                    </Field>
                  </div>

                  {submitSucceeded && (
                    <div className="appointment-confirmed">
                      <p>
                        Your appointment request has been sent, we will get in
                        touch to confirm your appointment.
                      </p>
                    </div>
                  )}
                </div>

                <button className="button button-primary" type="submit">
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  )
}
