import React, { Component } from 'react'

import IconLink from './../components/iconLink/IconLink'
import AppointmentForm from './../components/appointmentForm/AppointmentForm'
import HalfImage from './../components/halfImage/HalfImage'
import Layout from './../components/layout'
import Number from './../components/number/Number'

//Images
import headerImage from './../images/header/leda-homecare-bassetlaw-mansfield-worksop-nottingham.png'
import leftImage from './../images/main/2.png'
import rightImage from './../images/main/3.png'

//Icons
import arrowHeart from './../images/icons/arrow-heart.svg'
import houseHeart from './../images/icons/house-heart.svg'
import humanHeart from './../images/icons/human-heart.svg'
import target from './../images/icons/target.svg'

export class IndexPage extends Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.src =
      '//www.cqc.org.uk/sites/all/modules/custom/cqc_widget/widget.js?data-id=1-616510538&data-host=www.cqc.org.uk&type=location'
    script.async = true

    document.getElementById('qualityCareWidget').appendChild(script)

    const script1 = document.createElement('script')

    script1.src =
      'https://api.homecare.co.uk/assets/js/aggregate_rating.js?displayid=65432184764'
    script1.async = true
    script1.id = 'tg-aggregate-rating'
    script1.type = 'text/javascript'

    document.getElementById('homecareWidget').appendChild(script1)

    const script2 = document.createElement('script')

    script2.src =
      'https://api.homecare.co.uk/assets/js/review_widget.js?displaydiv=tgrw-aae2b00c&displayid=65432184764&displaycontent=snippet&displaywidth=300&displaycount=2&displayscore=true&displaylink=true&displayborder=true&displaybackgroundcolor=faded&displaypagination=false&displaystrapline=true&displayfontsize=default&displayallratings=false&displaylogo=true&displaywrappers=true&displaybutton=true&displaysettingname=true'
    script2.async = true
    script2.className = 'tg-review-widget'

    document.getElementById('homecareWidget').appendChild(script2)

    const display = document.createElement('div')

    display.className = 'tg-review-widget-container'
    display.id = 'tgrw-aae2b00c'

    document.getElementById('homecareWidget').appendChild(display)
  }

  render() {
    return (
      <Layout>
        <div className="home">
          <div
            className="header-image"
            style={{ backgroundImage: `url(${headerImage})` }}
          />
          <div className="container">
            <AppointmentForm />
            <div className="row">
              <div className="twelve columns">
                <h2 className="center-text">We Are Leda Homecare</h2>
                <p className="center-text">
                  We are a local based company who specialise in recruiting
                  skilled and competent care and support workers to provide
                  domiciliary care to a wide range of service users.
                </p>

                <div className="row">
                  <div className="four columns">
                    <IconLink
                      icon={arrowHeart}
                      title="Respite Care"
                      link="/services#respiteCare"
                    />
                  </div>
                  <div className="four columns">
                    <IconLink
                      icon={houseHeart}
                      title="Help at Home"
                      link="/services#homeCare"
                    />
                  </div>
                  <div className="four columns">
                    <IconLink
                      icon={humanHeart}
                      title="Younger Adults"
                      link="/services#youngCare"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HalfImage
            image={leftImage}
            background
            imagePosition="left"
            text={[
              <p key="1">
                We are based in Worksop, Nottinghamshire and aim to serve
                Bassetlaw, Mansfield and parts of South Yorkshire. We recruit
                local, friendly and professional support workers who provide a
                comprehensive range of quality health care related services to
                any community.
              </p>,
              <p key="2">
                We provide practical support and personal care to adults of any
                age. We recognise the strain that constant care can have on
                family and friends committed to looking after their loved ones
                and relatives in their own homes. We can provide a welcome break
                for a holiday or just a few hours off.
              </p>,
            ]}
          />
          <div className="container">
            <div className="row">
              <div className="four columns">
                <Number number="250" title="Happy Clients" />
              </div>
              <div className="four columns">
                <Number number="309" title="Helped at Home" />
              </div>
              <div className="four columns">
                <Number number="70" title="Caring Staff" />
              </div>
            </div>
          </div>
          <div
            className="full-primary-light"
            style={{
              padding: '20px 0',
            }}
          >
            <div className="container">
              <div className="row desktop-flex">
                <div className="four columns">
                  <img
                    src={target}
                    alt=""
                    style={{
                      height: '150px',
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                </div>
                <div
                  className="eight columns"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ marginBottom: 0 }}>
                    Our <b>mission</b> is to supply the very best cost effective
                    services to the individual, both long and short term, and we
                    pride ourselves on the high quality staff we employ.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <HalfImage
            image={rightImage}
            background
            imagePosition="right"
            text={[
              <p key="1">
                We place strong emphasis on the highest standard and quality of
                service possible for all our service users. We believe that no
                matter how good its present services are there will always be
                room for improvement and development. We aim to give the highest
                quality of service in the safest environment where service
                users, families, carers and staff are listened to and valued.
              </p>,
              <ul key="2">
                <li>Care Standards Act 2000</li>
                <li id="quality-care-widget-before">
                  Certificate of Registration: C530002428
                </li>
              </ul>,
            ]}
          />
        </div>
        <div className="container widgets">
          <div className="row desktop-flex">
            <div className="six columns">
              <div id="qualityCareWidget" />
            </div>
            <div className="six columns">
              <div id="homecareWidget" />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
