import React from 'react'

import './Number.css'

const Number = (props) => (
  <div className="number-counter">
    <span>{props.number}</span>
    <h3 className="center-text">{props.title}</h3>
  </div>
)

export default Number
